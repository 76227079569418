<template>
  <v-card 
    elevation="0"
    class="pt-0 applications-list"
  >
    <v-row>
      <v-col
        v-for="application in sortedApplications"
        :key="application[0].id"
        class="py-0"
      >
        <!-- Application Card -->
        <v-card
          elevation="0"
          min-width="300"
        >
          <v-card-title>{{ application[0].element.application }}</v-card-title>
          <v-divider class="mx-4" />
          <v-card-text 
            raised
            class="pb-0"
          >
            <div
              v-for="elementConnection in application"
              :key="elementConnection.id"
              class="py-2"
            >
              <v-tooltip
                v-if="elementConnection.TTL || elementConnection.scheduledDate"
                bottom
                color="primary"
                max-width="300px"
              >
                <template v-slot:activator="{ on }">
                  <v-hover 
                    v-slot:default="{ hover }"
                  >
                    <v-card 
                      v-on="on" 
                      flat
                      style="border-style: solid; border-width: 1px; border-color: #CCCCCC"
                    >
                      <v-row class="pl-3">
                        <div :class="elementConnection.type.toLowerCase()" />
                        <v-col class="pl-3">
                          <v-row 
                            class="pa-2 pl-3 fill-height" 
                            align="center" 
                            justify="end"
                          >
                            <p class="element ma-0">
                              {{ elementConnection.element.alias }}
                            </p>
                            <v-spacer />
                            <v-icon
                              v-if="hover && isAdmin"
                              small
                              class="mr-4"
                              @click="removeElementEdge(elementConnection.id)"
                            >
                              mdi-close
                            </v-icon>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-hover>
                </template>
                <span v-if="elementConnection.type == 'Scheduled'"> 
                  Scheduled removal on: {{ new Date(elementConnection.TTL) }}
                </span>
                <span v-else-if="elementConnection.type == 'Pending'"> 
                  Pending
                </span>
                <span v-else-if="elementConnection.type == 'ErrorAdd'"> 
                  Encountered an error while adding this element
                </span>
                <span v-else-if="elementConnection.type == 'ErrorRemove'"> 
                  Encountered an error while removing this element
                </span>
              </v-tooltip>
              <v-hover
                v-else
                v-slot:default="{ hover }"
              >
                <v-card
                  flat
                  style="border-style: solid; border-width: 1px; border-color: #CCCCCC"
                >
                  <v-row class="pl-3">
                    <div :class="elementConnection.type.toLowerCase()" />
                    <v-col class="pl-3">
                      <v-row 
                        class="pa-2 pl-3 fill-height" 
                        align="center" 
                        justify="end"
                      >
                        <p class="element ma-0">
                          {{ elementConnection.element.alias }}
                        </p>
                        <v-spacer />
                        <v-icon
                          v-if="hover && isAdmin"
                          small
                          class="mr-4"
                          @click="removeElementEdge(elementConnection.id)"
                        >
                          mdi-close
                        </v-icon>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-hover>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";

const deleteElementEdge = `mutation ($edgeID: ID!) {
  deleteElementConnection(input: {id: $edgeID}) {
    id
  }
}`

export default {
  name: 'PersonApplications',
	props: [
    "elements",
    "selectedAttribute",
  ],
  data () {
    return {
      isAdmin: this.$store.state.user.userGroups.includes('admin')
    }
  },
  computed: {
    sortedApplications () {
      if (this.elements) {

        // Create an array of unique applications from the object of elements
        let uniqueApplications = [...new Set(this.elements.map(item => item.element.application))].sort();
        let applications = []

        // Build an object keyed by application instead of an array of elements
        for (let i = 0; i < uniqueApplications.length; i++) {

          if (!this.selectedAttribute) {
            applications[i] = this.elements.filter(element =>
              element.element.application == uniqueApplications[i]
            )
          } else {
            applications[i] = this.elements.filter(element =>
              element.element.application == uniqueApplications[i] && element.element.attributes.includes(this.selectedAttribute)
            )
          }
        }

        let filteredApplications = applications.filter(application => application.length)

        return filteredApplications.sort(function(a,b) { return b.length - a.length})

      } else {
        return []
      }
    }
  },

  methods: {
    removeElementEdge(edgeID) {
      API.graphql(graphqlOperation(
        deleteElementEdge, {
          edgeID: edgeID
        }
      ))
      .then(this.$emit('reloadApplications'))
      .catch(error => {console.log(error)})
    }
  }
}
</script>

<style>

.element {
  font-size: 16px;
}

.access {
  width: 1.5em;
  background-color: #10A170;
}

.pending {
  width: 1.5em;
  background-color: #FFB700;
}

.scheduled {
  width: 1.5em;
  background-color: #2196F3;
}

.erroradd, .errorremove, .errorother {
  width: 1.5em;
  background-color: #A3082A;
}

.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}

.applications-list {
  overflow-y: hidden;
}

@media only screen and (min-width: 600px) {
  .applications-list {
    overflow-y: auto;
  }
}

</style>